import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh proxycommand`}</strong>{` -- proxy ssh connections according to the host registry`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh proxycommand <user> <host> <port>
[--provisioner=<name>] [--set=<key=value>] [--set-file=<file>]
[--offline] [--ca-config=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh proxycommand`}</strong>{` looks into the host registry
and proxies the ssh connection according to its configuration. This command
is used in the ssh client config with `}<inlineCode parentName="p">{`ProxyCommand`}</inlineCode>{` keyword.`}</p>
    <p>{`This command will add the user to the ssh-agent if necessary.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`user`}</inlineCode>{`
The remote username, and the subject used to login.`}</p>
    <p><inlineCode parentName="p">{`host`}</inlineCode>{`
The host to connect to.`}</p>
    <p><inlineCode parentName="p">{`port`}</inlineCode>{`
The port to connect to.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pair with template data variables. Use the `}<strong parentName="p">{`--set`}</strong>{` flag multiple times to add multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      